import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { withFirebase } from "firebase/index.js";

const validEmailRegex = RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i);

const INITIAL_STATE = {
  formSent: false,
  submitError: null,
  email: '',
  errors: {
    email: ""
  }
};

class ReceiveInviteSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE}
  }

  validateForm = state => {
    let valid = state.email != null && state.email.length > 0;
    Object.values(state.errors).forEach(
      val => val.length > 0 && (valid = false)
    );
    return valid;
  };

  handleChange = event => {
    event.preventDefault();

    const { name, value } = event.target;
    let errors = this.state.errors;
    if (name === "email") {
      errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
    }
    this.setState({ errors, [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { email } = this.state;

    if (this.validateForm(this.state)) {
      this.props.firebase.doSubscribe(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({ submitError: null, formSent: true });
      })
      .catch(error => {
        this.setState({ submitError: error, formSent: false });
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <a id="subscribe" href="#subscribe">
              <h2 className={classes.title}>
                Be first to try this product out!
              </h2>
            </a>
            <h5 className={classes.description}>
              We&apos;re working on a tool for HR teams and team managers to
              simplifying the performance evaluation process of employees. Sign
              up to receive an invite to a new product!
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={6}>
              <form style={{ width: "100%" }} onSubmit={this.handleSubmit}>
                <CustomInput
                  labelText="Enter your email address"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={errors.email.length > 0}
                  inputProps={{
                    name: "email",
                    onChange: this.handleChange,
                    value: this.state.email
                  }}
                />
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="info"
                >
                  Receive Invite
                </Button>
                <div>
                  &nbsp;
                  {!this.state.formSent && this.state.submitError ? (
                    <span className={classes.errorMessage}>
                      {this.state.submitError}
                    </span>
                  ) : null}
                  {this.state.formSent ? (
                    <span className={classes.successMessage}>
                      &#10004;&nbsp;saved
                    </span>
                  ) : null}
                </div>
              </form>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} />
          </GridContainer>
        </div>
      </div>
    );
  }
}

ReceiveInviteSection.propTypes = {
  classes: PropTypes.object,
  firebase: PropTypes.object
};

export default withStyles(teamStyle)(withFirebase(ReceiveInviteSection));
