import React from "react";
import ReactDOM from "react-dom";

import Firebase, { FirebaseContext } from './firebase';

import PE360App from "./PE360App";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <PE360App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
