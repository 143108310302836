import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import Pages from "@material-ui/icons/Pages";
import TrackChanges from "@material-ui/icons/TrackChanges";
import Group from "@material-ui/icons/GroupWork";
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Chart from "@material-ui/icons/Notifications";
import School from "@material-ui/icons/School";
import ThumbUp from "@material-ui/icons/ThumbUp";

class FeaturesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.title}>Features</h2>
            <h5 className={classes.description}>
              Performance Evaluation is defined as a formal and productive
              procedure to measure an employee’s work and results based on their
              job responsibilities. We provide all the required tools to make
              this process as easy as possible.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Performance evaluation process"
                description="Using predefined forms for different employees roles smart wizard will take performance evaluation simple and comfortable."
                icon={Pages}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Smart analytics engine"
                description="This engine will try to detect professional burn out of the employee and help you to understand the level of team satisfaction."
                icon={TrackChanges}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Notification system"
                description="You can create a schedule of evaluations in your team, and service will notify you about the required next performance evaluation."
                icon={Chart}
                iconColor="info"
                vertical
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Mentorship program"
                description="Track how your internal mentorship program goes with goals, requests and results"
                icon={School}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Employees promotions"
                description="Manage the promotion process in your organisation"
                icon={ThumbUp}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Team management"
                description="You can move the employee from one team to another, change team manager, etc. All employee PE results history will keep with the employee."
                icon={Group}
                iconColor="info"
                vertical
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Remarks service"
                description="You can collect remarks about employee work all the time to make the performance evaluation process more effective."
                icon={Chat}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Privacy and security"
                description="This service is fully GDPR compliant. All sensitive user information is encrypted in our database and can be removed by request."
                icon={VerifiedUser}
                iconColor="info"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

FeaturesSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(FeaturesSection);
