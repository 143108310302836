import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.jsx";

import { withFirebase } from './firebase';

import "assets/scss/material-kit-react.scss?v=1.7.0";

let hist = createBrowserHistory();

const LoggedInRoute = ({ authUser, ...props }) =>
  authUser ? <Redirect to="/app" /> : <Route {...props} />;

LoggedInRoute.propTypes = {
  authUser: PropTypes.object
};

const Notfound = () => <h1>Page not found</h1>;

const PE360App = () => (
        <Router history={hist}>
          <Switch>
            <Route path="/" component={LandingPage} />
            <Route component={Notfound} />
          </Switch>
        </Router>
    );

export default withFirebase(PE360App)
